import Navbar from "../components/navbar/navbar"
import icon_next from '../next.png'

export default function Contact() {
    return (
        <main>
            <Navbar />

             <div className='view_main_container'>
                <div className='view_main'>

                    <div className='view_main_text'>
                        <p className='view_main_text_subtitle'>Estamos disponibles para hablar sobre su proyecto.</p>
                        <div className='view_contact_adress'>
                            <p className='view_contact_text'>Hacienda Santa Patrcia Local 3-A.</p>
                            <p className='view_contact_text'>Fracc. Camino Real 34170 Durango, Durango México.</p>
                            <p className='view_contact_text'>( 618 ) 812 68 07</p>
                        </div>
                        <div className='view_main_button'>
                            <a href='tel:6188126807' className='view_main_button_link'>Llamar Ahora <div className='view_main_button_link_image'><img src={icon_next} alt='icon' className='view_main_button_link_image_icon' /></div></a>
                        </div>
                    </div>

                </div>
            </div>

        </main>
    )
}