import React from 'react';
import Navbar from './components/navbar/navbar';
import FadeIn from 'react-fade-in/lib/FadeIn';
import icon_next from './next.png'

import './App.css';

function App() {
  return (
    <div className="App">

      <Navbar />


      <div className='view_main_container'>
        <div className='view_main'>

          <div className='view_main_text'>
            <FadeIn transitionDuration='800' delay='300'>
              <h1 className='view_main_text_title'>Soluciones en Ingeniería Civil.</h1>
              <h2 className='view_main_text_subtitle'>Implementamos las mejores soluciones en infraestructura, captación y conducción.</h2>
            </FadeIn>
            <div className='view_main_button'>
              <a href='tel:6188126807' className='view_main_button_link'>Llamar Ahora <div className='view_main_button_link_image'><img src={icon_next} alt='icon' className='view_main_button_link_image_icon' /></div></a>
            </div>
          </div>

        </div>
      </div>

      <div className='view_solutions_container' id='solutions'>

        <div className='view_solutions'>

          <div className='view_solutions_card'>
              <p className='view_solutions_card_title'>Distribución de Agua Potable.</p>
              <p className='view_solutions_card_paragraph'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                
          </div>

          <div className='view_solutions_card'> 
              <p className='view_solutions_card_title'>Tratamiento de Aguas Residuales.</p>
              <p className='view_solutions_card_paragraph'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
          </div>

          <div className='view_solutions_card'> 
              <p className='view_solutions_card_title'>Sistemas de Alcantarillado.</p>
              <p className='view_solutions_card_paragraph'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
          </div>

          <div className='view_solutions_card'> 
              <p className='view_solutions_card_title'>Ingeniería Ambiental.</p>
              <p className='view_solutions_card_paragraph'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
          </div>

          <div className='view_solutions_card'> 
              <p className='view_solutions_card_title'>Infraestructura.</p>
              <p className='view_solutions_card_paragraph'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
          </div>
    
        </div>

          
      </div>

    </div>
  );
}

export default App;
