import Navbar from "../components/navbar/navbar";

import PTAR_JC from '../media/PTAR_JC.png'
import RPB_JC from '../media/RPB_JC.png'
import PTAR_DK from '../media/PTAR_DK.png'
import PTAR_CLI from '../media/PTAR_CLI.png'
import CLD_DK from '../media/CLD_DK.png'
import CP_JC from '../media/CP_JC.png'
import LC_PP from '../media/LC_PP.png'
import APDP_JC from '../media/APDP_JC.png'
import SA_BS from '../media/SA_BS.png'
import LAR_PP from '../media/LAR_PP.png'
import LAP_TC from '../media/LAP_TC.png'
import AP_JC from '../media/AP_JC.png'

import FadeIn from 'react-fade-in/lib/FadeIn';

export default function Proyects() {
    return (
        <main>
            <Navbar />

            

            <div className='view_main_container'>
                <div className='view_proyect'>

                    <FadeIn delay={100}>
                        <div className='view_main_proyect'>
                            <div><img src={PTAR_JC} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Planta de Tratamiento de Aguas Residuales.</p>
                                <p className='view_main_proyect_text_paragraph'>Jonhson Controls Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>
                    
                    <FadeIn delay={200}>
                        <div className='view_main_proyect'>
                            <div><img src={RPB_JC} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Reposición Piso de Concreto y Bacheo.</p>
                                <p className='view_main_proyect_text_paragraph'>Jonhson Controls Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={300}>
                        <div className='view_main_proyect'>
                            <div><img src={PTAR_DK} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Planta de Tratamiento de Aguas Residuales.</p>
                                <p className='view_main_proyect_text_paragraph'>Draka Prysmian Group Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={400}>
                        <div className='view_main_proyect'>
                            <div><img src={PTAR_CLI} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Planta de Tratamiento de Aguas Residuales.</p>
                                <p className='view_main_proyect_text_paragraph'>Centro Logistico Industrial Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={500}>
                        <div className='view_main_proyect'>
                            <div><img src={CLD_DK} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Linea Drenaje de Descarga Pluvial.</p>
                                <p className='view_main_proyect_text_paragraph'>Draka Prysmian Group Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={600}>
                        <div className='view_main_proyect'>
                            <div><img src={CP_JC} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Cruce Pluvial de Dren.</p>
                                <p className='view_main_proyect_text_paragraph'>Jonhson Controls Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={700}>
                        <div className='view_main_proyect'>
                            <div><img src={LC_PP} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Linea de Conducción 300MM.</p>
                                <p className='view_main_proyect_text_paragraph'>Planta Peñoles Torreón, Coah. México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={800}>
                        <div className='view_main_proyect'>
                            <div><img src={APDP_JC} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Ampliación de Puente en Dren Pluvial.</p>
                                <p className='view_main_proyect_text_paragraph'>Jonhson Controls Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={900}>
                        <div className='view_main_proyect'>
                            <div><img src={SA_BS} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Sistema de Alcantarillado y Agua Potable.</p>
                                <p className='view_main_proyect_text_paragraph'>Blvd. La Salle Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={1000}>
                        <div className='view_main_proyect'>
                            <div><img src={LAR_PP} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Interconexión de Linea Agua Residual.</p>
                                <p className='view_main_proyect_text_paragraph'>Planta Peñoles Torreón, Coah. México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={1100}>
                        <div className='view_main_proyect'>
                            <div><img src={LAP_TC} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Linea de Agua Potable.</p>
                                <p className='view_main_proyect_text_paragraph'>Col. Metalurgica Torreón, Coah. México.</p>
                            </div>
                        </div>
                    </FadeIn>

                    <FadeIn delay={1200}>
                        <div className='view_main_proyect'>
                            <div><img src={AP_JC} alt='' className='view_main_proyect_image'/></div>
                            <div className='view_main_proyect_text'>
                                <p className='view_main_proyect_text_title'>Ampliación de Planta.</p>
                                <p className='view_main_proyect_text_paragraph'>Jonhson Controls Durango, Durango México.</p>
                            </div>
                        </div>
                    </FadeIn>

                </div>
            </div>
        </main>
    );
}