import React from "react";
import { Link } from 'react-router-dom';

import FadeIn from 'react-fade-in/lib/FadeIn';

import logo from './Logo.png'

function Navbar() {
    return (
        <div className='view_header_container'>
            <div className='view_header'>
            <FadeIn transitionDuration='800'>
                <div>
                    <Link to='/'><img src={logo} alt='Logo INFRASAC' className='view_header_logo'/></Link>
                    <p className='view_header_text'>INFRAESTRUCTURA, SANEAMIENTO Y CONDUCCIÓN S.A. DE C.V.</p>
                </div>
            </FadeIn>
                <nav>
                <ul className='view_header_list'>
                    <li className='view_header_list_item'>
                        <Link to='/' className='view_header_list_item_link'>SOLUCIONES</Link>
                    </li>
                    <li className='view_header_list_item'>
                        <Link to='/proyectos' className='view_header_list_item_link'>PROYECTOS</Link>
                    </li>
                    <li className='view_header_list_item'>
                        <Link to='/contacto' className='view_header_list_item_link'>CONTACTO</Link>
                    </li>
                </ul>
                </nav>
            </div>
        </div>
    )
}

export default Navbar